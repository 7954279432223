@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
}

.custom-arrow {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

.custom-prev {
  left: -17px;
}

.custom-next {
  right: -17px;
}

.home-container {
  width: 100vw;
  height: 100vh;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 65px);
}

.contr {
  width: 100%;
  height: auto;
}

.contr .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8%;
  gap: 0.5rem;
}

.header-title {
  font-size: 1.5rem;
  font-weight: 900;
}

.header-subtitle {
  font-size: 0.8rem;
  color: #666;
}

.dashed-line {
  display: none;
}

.cars {
  width: 90%;
  margin: auto;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.6s ease-in-out;
}

.card:hover {
  transform: scale(1.01);
  cursor: pointer;
}

.img-cont {
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15%;
}

.img {
  width: 85%;
  margin: auto;
  height: 220px;
  border-radius: 10px;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
  margin: 4% 0;
  text-transform: uppercase;
}

.line {
  width: 45%;
  border-top: 2px dashed #bab4b4;
  margin-bottom: 6%;
}

.card-text {
  font-size: 1rem;
  color: #666;
  text-align: center;
}

.btn {
  color: white;
  background-color: rgb(150, 191, 1);
  width: 2.5rem;
  height: 2.5rem;
  border: none;
}

.btn:hover {
  background-color: rgb(98, 123, 7);
  cursor: pointer;
}

.prev {
  border-radius: 0 25px 25px 0;
}

.next {
  border-radius: 25px 0 0 25px;
  margin-left: auto;
}

.prev.disabled,
.next.disabled {
  background-color: #cecaca;
}

.home-social-links {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 5%;
  color: #605d5d;
  font-size: 0.5rem;
}

.home-social-links li {
  padding: 0.5rem;
  border: 2px solid #d1cece;
  border-radius: 50%;
  cursor: pointer;
}

.home-social-links li:last-child {
  display: none;
}

.home .loading {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20rem;
}

.home .no-cars {
  font-size: 2rem;
  color: #ff8c00;
  text-align: center;
  margin-bottom: 20rem;
}

a {
  text-decoration: none;
  color: black;
}

.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #80a301 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #80a301);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 0.5s infinite linear;
}

@keyframes s3 {
  100% { transform: rotate(1turn); }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .custom-arrow {
    top: 23%;
  }

  .custom-prev {
    left: -38px;
  }

  .custom-next {
    right: -38px;
  }

  .home-container {
    display: flex;
  }

  .contr .header {
    gap: 0;
    margin-bottom: 3%;
  }

  .header-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .header-subtitle {
    margin-bottom: 1.6rem;
  }

  .home {
    height: 100%;
    width: 100%;
  }

  .contr {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card {
    min-height: 100%;
    width: 80%;
    margin: auto;
  }

  .img-cont {
    margin-bottom: 10%;
    width: 25vw;
    height: 27vh;
  }

  .img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  .line {
    width: 25%;
  }

  .dashed-line {
    display: block;
    width: 12%;
    border-top: 2px dashed #ebebeb;
  }

  .card-title {
    font-size: 1rem;
  }

  .card-text {
    font-size: 0.8rem;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }
}

@media screen and (min-width: 900px) {
  .custom-prev {
    left: -50px;
  }

  .custom-next {
    right: -50px;
  }

  .home-container {
    display: flex;
  }

  .contr .header {
    gap: 0;
    margin-bottom: 5%;
  }

  .header-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .header-subtitle {
    margin-bottom: 1.6rem;
  }

  .home {
    height: 100%;
    width: calc(100% - 250px);
  }

  .contr {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card {
    min-height: 100%;
    width: 80%;
    margin: auto;
  }

  .img-cont {
    margin-bottom: 10%;
    width: 25vw;
    height: 27vh;
  }

  .img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  .line {
    width: 25%;
  }

  .dashed-line {
    display: block;
    width: 12%;
    border-top: 2px dashed #ebebeb;
  }

  .card-title {
    font-size: 1.2rem;
  }

  .card-text {
    font-size: 0.8rem;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  .btn {
    width: 2.8rem;
    height: 2.8rem;
  }

  .home .no-cars {
    width: 35%;
  }
}
