@import url('https://fonts.googleapis.com/css2?family=Lugrasimo&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
}

header {
  display: none;
}

#navigation-panel {
  height: 100vh;
  width: 250px;
  background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
  background-color: #eeeeee68;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  justify-content: space-between;
}

.nav-close-btn {
  display: none;
}

#navigation-panel .logo {
  width: 100%;
}

.logo img {
  width: 100%;
  mix-blend-mode: darken;
}

nav {
  position: relative;
  bottom: 50px;
}

nav ul {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

nav ul li a {
  color: #000;
  text-decoration: none;
  font-size: 18px;
  display: block;
  padding: 15px 5px 15px 12px;
  transition: 0.3s ease all;
  font-family: 'Lugrasimo', cursive;
}

nav ul li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

nav ul li.active {
  background-color: rgb(150, 191, 1);
}

nav ul li.active a {
  font-weight: bolder;
}

footer .social-icons {
  display: flex;
  justify-content: center;
  gap: 12px;
}

footer .social-icons li {
  border-radius: 50%;
  padding: 8px 8px 6px;
  font-size: 18px;
  cursor: pointer;
  transition: 0.4s ease all;
  border: 1px solid rgb(150, 191, 1);
  font-weight: bolder;
}

footer .social-icons li:hover {
  background-color: #fff;
  border: 1px solid rgb(150, 191, 1);
  transform: scale(1.3);
}

footer p {
  margin-top: 14px;
  font-size: 15px;
  text-align: center;
}

.overlay {
  display: none;
}

.logout {
  width: fit-content;
  padding: 10px 40px;
  outline: none;
  border: 1px solid rgb(150, 191, 1);
  background-color: inherit;
  font-family: 'Lugrasimo', cursive;
  transition: all 0.3s ease;
  margin-left: 10%;
  position: absolute;
  bottom: -30%;
  cursor: pointer;
}

.logout:hover,
.logout:focus {
  border: none;
  background-color: rgba(241, 50, 50, 0.641);
  color: #fff;
  font-weight: bolder;
  transform: scale(0.9);
}

.logout-icon {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 3px;
}

@media (max-width: 900px) {
  header {
    display: block;
    width: 100%;
    position: relative;
    height: 62px;
  }

  header .mobile-nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    height: 60px;
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
    border-bottom: 1px solid #bebebe68;
    background-color: #fff;
  }

  header .mobile-nav-container .logo {
    width: 230px;
  }

  header .mobile-nav-container .logo img {
    height: 100%;
    width: 100%;
  }

  header .mobile-nav-container .nav-bars {
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
  }

  #navigation-panel {
    position: fixed;
    width: 220px;
    background-color: rgb(135, 172, 0);
    z-index: 30;
    top: 0;
    transition: left 0.3s ease;
    left: -100%;
  }

  #navigation-panel.active {
    left: 0;
  }

  .nav-close-btn {
    display: block;
    text-align: end;
    position: absolute;
    right: 0;
  }

  .nav-close-btn button {
    margin-right: 20px;
    outline: none;
    border-radius: 50%;
    padding: 8px 12px;
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
    font-weight: bolder;
    border: none;
    cursor: pointer;
  }

  .nav-close-btn button:active {
    background-color: rgba(0, 0, 0, 0.4);
  }

  #navigation-panel .logo {
    display: none;
  }

  nav {
    position: relative;
    bottom: 0;
    top: 52px;
  }

  nav ul {
    gap: 6px;
    margin-left: 5px;
  }

  nav ul li.active {
    background-color: #fff;
  }

  nav ul li a {
    font-size: 18px;
    padding: 10px 5px 10px 12px;
  }

  footer .social-icons li {
    border-radius: 50%;
    padding: 8px 8px 6px;
    font-size: 15px;
    transition: 0.2s ease all;
    border: 1px solid #fff;
  }

  footer .social-icons li:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transform: scale(1.2);
  }

  .overlay {
    display: block;
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    left: -100%;
    cursor: pointer;
    z-index: 8;
  }

  .active {
    left: 0;
  }

  .logout-cont {
    margin: 60px 0 0 18px;
  }

  .logout {
    border: 2px solid #d0d0d0;
    margin-left: 1%;
    padding: 10px 30px;
  }

  .logout-icon {
    margin-left: 10px;
    color: red;
  }

  .logout:hover .logout-icon,
  .logout:focus .logout-icon {
    color: #fff;
  }
}
