@media (max-width: 765px) {
  #reservation-page .reservation_main_container {
    display: flex;
    flex-direction: column;
  }

  #reservation-page .reservation_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: url('https://s1.1zoom.me/big0/660/Shelby_Super_Cars_White_background_White_559662_1280x853.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  #reservation-page .reservations_header {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: black;
    font-weight: 700;
  }

  #reservation-page .card_main_container {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 40px;
    overflow: hidden;
    margin-bottom: 40px;
  }

  #reservation-page .card_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  #reservation-page .card_wrapper {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border: 1px solid white;
    background-color: rgba(0, 0, 0, 0.647);
    border-radius: 7px;
    font-weight: 700;
    color: white;
  }

  #reservation-page .card_detail {
    display: flex;
    align-items: center;
    padding: 12px;
    height: 2rem;
    font-size: 20px;
  }

  #reservation-page h2.card_detail {
    justify-content: center;
  }

  #reservation-page .delete_reservation {
    background-color: #ff5f5f;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
  }

  #reservation-page .delete_reservation:hover {
    background-color: #fc5d5daa;
  }
}

@media (min-width: 768px) {
  .reservation_main_container {
    display: flex;
  }

  .reservation_container {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-image: url('https://s1.1zoom.me/big0/660/Shelby_Super_Cars_White_background_White_559662_1280x853.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  #reservation-page .reservations_header {
    text-align: center;
    margin-top: 2rem;
    font-size: 2rem;
  }

  #reservation-page .card_main_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
  }

  #reservation-page .card_container {
    display: flex;
    width: 390px;
    gap: 20px;
    padding: 20px;
  }

  #reservation-page .card_wrapper {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border: 1px solid white;
    background-color: rgba(17, 17, 17, 0.776);
    border-radius: 7px;
    z-index: 10;
    color: white;
    font-weight: 700;
  }

  #reservation-page .card_detail {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px;
    height: 3rem;
  }

  #reservation-page h2.card_detail {
    justify-content: center;
  }

  #reservation-page .delete_reservation {
    background-color: #ff5f5f;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
  }

  #reservation-page .delete_reservation:hover {
    background-color: #fc5d5daa;
  }
}
